import React, { useState } from "react";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { formatDate } from  '../../../utils/format';
import './styles.css';

const Action = (props) => {
  const { action, onEventAdd } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = () => setIsModalOpen(!isModalOpen);
  const onModalEventAdd = (actionId, eventType) => {
    onEventAdd(actionId, eventType);
    setIsModalOpen(false);
    action.title += '-upd';
  };


  return (
    <div>
      <Row className="d-flex justify-content-between">
        <Col className="mb-2">
          <div className="smaller">
            {action.schedule && (<span>{formatDate(action.schedule)} : </span>)}
            {action.due && (<span>{formatDate(action.due)} : </span>)}
            [{action.effort}] : 
            {action.parentProjects.map((parent, i) => (
              <span key={i}>{i>0 && ( '>>' )}{parent.title}</span>
            ))}
          </div>
          <span className={`mr-3 ${action.completed ? "strike bold" : ""}`}>
            {action.title}
          </span>
          <button onClick={() => onEventAdd(action.id.value, "Start")}><FontAwesomeIcon icon="play" /></button>
          <button onClick={() => onEventAdd(action.id.value, "Complete")}><FontAwesomeIcon icon="check-square" /></button>
          <button onClick={toggle}><FontAwesomeIcon icon="ellipsis-h" /></button>
        </Col>
      </Row>

      <Modal isOpen={isModalOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{action.title}</ModalHeader>
        <ModalBody>
          <p>Consider delegating</p>
          <button onClick={() => onModalEventAdd(action.id.value, "Start")}><FontAwesomeIcon icon="play" /></button> Start/Stop
          <button onClick={() => onModalEventAdd(action.id.value, "Complete")}><FontAwesomeIcon icon="check-square" /></button> Complete
          <button onClick={() => onModalEventAdd(action.id.value, "Prioritize")}><FontAwesomeIcon icon="arrow-up" /></button> Prioritize
          <button onClick={() => onModalEventAdd(action.id.value, "Postpone")}><FontAwesomeIcon icon="arrow-right" /></button> Postpone
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      
    </div>
  );
};

Action.propTypes = {
  action: PropTypes.object.isRequired,
  onEventAdd: PropTypes.func.isRequired,
};

// Action.defaultProps = {
//   staticContext: null,
//   primary: false,
// };

export default Action;
