import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// TODO: add 'touch' backend for mobile: https://react-dnd.github.io/react-dnd/docs/backends/touch

import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Status from "./views/Status";
import Focus from "./views/Focus";
import Groom from "./views/Groom";

import List from "./components/groom/TempList";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <DndProvider backend={HTML5Backend}>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/profile" component={Profile} />
              <Route path="/focus" component={Focus} />
              <Route path="/groom" component={Groom} />
              <Route path="/status" component={Status} />

              <Route path="/list" component={List} />
            </Switch>
          </DndProvider>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
