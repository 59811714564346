import * as Yup from 'yup';

export const ActionEffortOptions = [ 
    { effort:'XS', text:'Less than 5 min'}, 
    { effort:'SM', text:'Less than 15 min'}, 
    { effort:'MD', text:'Less than 30 min'}, 
    { effort:'LG', text:'Less than 1 hour'}, 
    { effort:'XL', text:'Less than 4 hours'}, 
    { effort:'XXL', text:'Less than 8 hours'}
];

export const ActionInitialized = {
    id: null,
    projectId: null,
    title: '',
    effort: '',
    committed: null,

    important: false,
    urgent: false,

    due: null,
    prioritized: null,
    completed: null,
    deleted: null,

    checklist: null,
    schedule: null,
    tags: null,
    waitOnActionId: null,
    timerEvents: null,
};

export const ActionScheduleInitialized = {
    start: null,
    repeatDay: null,
    repeatMonth: null,
    repeatOnComplete: true,
};

export const ActionValidation = Yup.object().shape({
    // projectId: Yup.string()
    // .required('Required'),
    title: Yup.string()
    .max(15, 'Must be 15 characters or less')
    .required('Required'),
    effort: Yup.string()
    .oneOf(ActionEffortOptions.map(x => x.effort), 'Invalid effort')
    .required('Required'),
});

export const ActionScheduleValidation = Yup.object().shape({
    start: Yup.date()
    .required('Required'),
    repeatDay: Yup.number()
    .min(0, 'Must be more than 0')
    .nullable(),
    repeatMonth: Yup.number()
    .min(0, 'Must be more than 0')
    .nullable(),
});