// determine apiUrl
console.log(process.env.REACT_APP_API);
const apiUrls = JSON.parse(process.env.REACT_APP_API);
const spaUrl = window.location.host;
let apiUrl = apiUrls[spaUrl];
if (!apiUrl) apiUrl = apiUrls["default"];

// set the env vars from the process for build time and override them from the window for runtime vars
window.env = {
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENTID: process.env.REACT_APP_AUTH0_CLIENTID,
  REACT_APP_AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_API: process.env.REACT_APP_API,
  spaUrl,
  apiUrl,
  ...window.env,
};
