import * as Yup from 'yup';

export const ProjectInitialized = {
    id: null,
    title: '',
    notes: '',
    deleted: null,
    children: [],
    actions: [],
    parents: [],
}

export const ProjectValidation = Yup.object().shape({
  title: Yup.string()
    .max(30, 'Must be 30 characters or less')
    .required('Required'),
    notes: Yup.string()
    .max(250, 'Must be 250 characters or less')
    .nullable(),
//   email: Yup.string()
//     .email('Invalid email address')
//     .required('Required'),
//   acceptedTerms: Yup.boolean()
//     .required('Required')
//     .oneOf([true], 'You must accept the terms and conditions.'),
//   jobType: Yup.string()
//     .oneOf(
//       ['designer', 'development', 'product', 'other'],
//       'Invalid Job Type'
//     )
//     .required('Required'),
});