import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './styles.css';

const TreeNode = (props) => {
  const { project, level, getChildren, onProjectSelected, moveProject } = props;
  const [isOpen, setIsOpen] = useState(true);
  const hasChildren = project?.children && Array.isArray(project.children) && project.children.length > 0;
  const onToggle = () => {
      setIsOpen(!isOpen);
  }

  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: 'item',
    item: { projectId: project.id.value },
    collect: (monitor) => ({
        isDragging: monitor.isDragging(),
    }),
  })

  // useDrop - the list item is also a drop area
  const [spec, dropRef] = useDrop({
      accept: 'item',
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
      // hover: (item, monitor) => {
      //     const dragIndex = item.index
      //     const hoverIndex = index
      //     const hoverBoundingRect = ref.current?.getBoundingClientRect()
      //     const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      //     const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

      //     // if dragging down, continue only when hover is smaller than middle Y
      //     if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
      //     // if dragging up, continue only when hover is bigger than middle Y
      //     if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

      //     item.index = hoverIndex
      // },
      drop: (item) => {
        moveProject(item.projectId, project.id.value);
      },
  })

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const ref = useRef(null)
  const dragDropRef = dragRef(dropRef(ref))

  // Make items being dragged transparent, so it's easier to see where we drop them
  const opacity = isDragging ? 0 : 1
  const paddingLeft = `${level * 20}px`;
  const nodeClassName = spec.isOver ? 'tree-node tree-node-hover' : 'tree-node';

  return (
    <>
      <div ref={dragDropRef} className={nodeClassName} style={{ paddingLeft, opacity}}>
        { hasChildren && (
          <div className='node-icon' onClick={() => onToggle()}>
            { (isOpen ? <FontAwesomeIcon icon="chevron-down" className="mr-2" /> : <FontAwesomeIcon icon="chevron-right" className="mr-2" />) }
          </div>
        )}
        
        <div className='node-icon'>
          { isOpen === true && <FontAwesomeIcon icon="folder-open" className="mr-2" /> }
          { !isOpen && <FontAwesomeIcon icon="folder" className="mr-2" /> }
        </div>

        <span role="button" onClick={() => onProjectSelected(project)}>
          { project.title }
        </span>
      </div>

      { isOpen && hasChildren && getChildren(project).map((child,i) => (
        <TreeNode 
          {...props}
          key={`${i.toString()}_${child.id.value}`}
          project={child}          
          level={level + 1}
          // index={i}
        />
      ))}
    </>
  );
}

TreeNode.propTypes = {
  project: PropTypes.object.isRequired,
  level: PropTypes.number,
  getChildren: PropTypes.func.isRequired,
  onProjectSelected: PropTypes.func.isRequired,
  moveProject: PropTypes.func.isRequired,
};

TreeNode.defaultProps = {
  level: 0,
};

export default TreeNode;
