import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../components/Loading";
import Tree from "../../components/groom/Tree";
import ProjectForm from "../../components/groom/Project/form";
import PeriodForm from "../../components/groom/Period/form";
import { ProjectInitialized } from '../../components/groom/Project/project';
import { formatDateISO } from  '../../utils/format';
import './styles.css';

export const Groom = () => {
  const [period, setPeriod] = useState(null);
  const [periodDate, setPeriodDate] = useState(new Date());
  console.log(setPeriodDate);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [updatedProjects, setUpdatedProjects] = useState([]);
  const [updatedActions, setUpdatedActions] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getProjects = async () => {
      try {
        const token = await getAccessTokenSilently();
        
        const response = await fetch(`${window.env.apiUrl}/api/groom/project`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const responseData = await response.json();

        setProjects(responseData.projects);
      } catch (error) {
        //TODO: how to handle errors
        console.error(error);
        // setState({
        //   ...state,
        //   error: error.error,
        // });
      }
    };

    //TODO: check if anything needs to be saved before getting fresh data
    getProjects();
  },[getAccessTokenSilently]);

  useEffect(() => {
    const getPeriod = async () => {
      try {
        const token = await getAccessTokenSilently();
        
        //TODO: eventually can move/jump to another period
        const response = await fetch(`${window.env.apiUrl}/api/groom/period/${formatDateISO(periodDate)}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const responseData = await response.json();

        setPeriod(responseData.period);
      } catch (error) {
        //TODO: how to handle errors
        console.error(error);
        // setState({
        //   ...state,
        //   error: error.error,
        // });
      }
    };

    //TODO: check if anything needs to be saved before getting fresh data
    getPeriod();
  },[getAccessTokenSilently, periodDate]);

  const saveProjects = async (projectList) => {
    try {
      const token = await getAccessTokenSilently();
      // const request  = JSON.stringify({ models: updatedProjects });
      const request  = JSON.stringify({ models: projectList });
      console.log("request",request);
      const response = await fetch(`${window.env.apiUrl}/api/groom/project`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: request
      });

      const responseData = await response.json();
      console.log('saveProjects',responseData);

      setUpdatedProjects([]);
    } catch (error) {
      //TODO: how to handle errors
      console.error(error);
      // setState({
      //   ...state,
      //   error: error.error,
      // });
    }
  };

  const saveActions = async (actionsList) => {
    try {
      const token = await getAccessTokenSilently();
      const request  = JSON.stringify({ Models: actionsList });
      const response = await fetch(`${window.env.apiUrl}/api/groom/action`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: request
      });

      const responseData = await response.json();
      console.log('saveActions',responseData);

      setUpdatedActions([]);
    } catch (error) {
      //TODO: how to handle errors
      console.error(error);
      // setState({
      //   ...state,
      //   error: error.error,
      // });
    }
  };

  const savePeriod = async (periodParam) => {
    try {
      const token = await getAccessTokenSilently();
      const request  = JSON.stringify({ Models: [ periodParam ] });
      const response = await fetch(`${window.env.apiUrl}/api/groom/period`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: request
      });

      const responseData = await response.json();
      console.log('savePeriod',responseData);

      //setUpdatedActions([]);
    } catch (error) {
      //TODO: how to handle errors
      console.error(error);
      // setState({
      //   ...state,
      //   error: error.error,
      // });
    }
  };

//   const [actionEvents, setActionEvents] = useState([]);
//   const addEvent = (actionId, eventType) => {
//     //TODO: add to local storage
//     const localDate = new Date();
//     console.log(localDate.toUTCString());
//     setActionEvents([
//         ...actionEvents,
//         { id: actionId, eventType, date: new Date()},
//     ]);
//   };

const onProjectChanged = (project) => {
    //update project list
    const allProjects = [...projects];
    const projectIndex = allProjects.findIndex(x => x.id.value === project.id.value);
    if (projectIndex === -1) {
      allProjects.push(project);
    }
    else {
      allProjects[projectIndex] = project;
    }
    setProjects(allProjects);

    // add to list to be saved
    const setUpdate = [...updatedProjects, project];
    setUpdatedProjects(setUpdate);
    console.log('updatedProjects', setUpdate);

    //for now save immediately - eventually save to local storage and sync
    saveProjects(setUpdate);
};

const onActionChanged = (action) => {
  // add to list to be saved
  const setUpdate = [...updatedActions, action];
  setUpdatedActions(setUpdate);
  console.log('updatedActions', setUpdate);

  //update period committed
  const committedActionIndex = period.committedActions?.findIndex(x => x.actionId === action.id.value) || -1;
  var updatedPeriod = undefined;
  if (action.committed && committedActionIndex === -1) {
    //add if not already there
    updatedPeriod = { ...period, committedActions: period.committedActions || [] };
    updatedPeriod.committedActions.push({actionId: action.id.value, effort: action.effort, isComplete: action.completed ? true : false });
  } else if (!action.committed && committedActionIndex !== -1) {
    //remove if already there
    updatedPeriod = { ...period };
    updatedPeriod.committedActions.splice(committedActionIndex, 1);
  }

  //for now save immediately - eventually save to local storage and sync
  saveActions(setUpdate);
  if (updatedPeriod) {
    savePeriod(updatedPeriod);
  }
};

const onPeriodChanged = (period) => {
  //for now save immediately - eventually save to local storage and sync
  savePeriod(period);
};

const onProjectSelected = (project) => {
    setSelectedProject(project);
};

const handleAddProject = () => {
  const newProject = {
    ...ProjectInitialized,
    id: { value: new Date().getTime().toString() },
  };
  console.log(newProject);
  setSelectedProject(newProject);
};

  return (
    <div className="row">
      <div className="col-md-4">
        <button
          type="button"
          className="secondary"
          onClick={handleAddProject}
        >
          Add project
        </button>

        {projects && (
          <div>
            <Tree projects={projects}
            onProjectSelected={onProjectSelected}
            onProjectChanged={onProjectChanged}></Tree>
          </div>
        )}
        {selectedProject && (
          <ProjectForm project={selectedProject} 
          onProjectChanged={onProjectChanged}
          onActionChanged={onActionChanged}></ProjectForm>
        )}
      </div>

      <div className="col-md-8">
        { period && (
          <PeriodForm period={period} 
          onPeriodChanged={onPeriodChanged}></PeriodForm>
        )}
      </div>
    </div>
  );
};

export default withAuthenticationRequired(Groom, {
  onRedirecting: () => <Loading />,
});
