import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { Formik, Form } from 'formik';
import { Text, TextArea } from '../../inputs';
import { ProjectInitialized, ProjectValidation } from './project';
import { ActionInitialized } from '../Action/action';
import { ActionList } from './actionlist';
import { ActionForm } from '../Action/form';

export const ProjectForm = (props) => {
    const { project, onProjectChanged, onActionChanged } = props;  
    const [actions, setActions] = useState([]);
    const [selectedAction, setSelectedAction] = useState(null);

    const { getAccessTokenSilently } = useAuth0();
    useEffect(() => {
      const getActions = async () => {
        try {
          const token = await getAccessTokenSilently();
          
          const actionIdCSV = project.actions ? project.actions.map(x => x.value).join() : []; 
          const response = await fetch(`${window.env.apiUrl}/api/groom/action/${project.id.value}?ActionIdSortCSV=${actionIdCSV}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const responseData = await response.json();
  
          setActions(responseData.actions);
        } catch (error) {
          //TODO: how to handle errors
          console.error(error);
          // setState({
          //   ...state,
          //   error: error.error,
          // });
        }
      };
      
      getActions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[project]);

    const onMoveListItem = (dragIndex, hoverIndex) => {
      // const dragItem = actions[dragIndex];
      // const hoverItem = actions[hoverIndex];
      // // Swap places of dragItem and hoverItem in the list
      // setActions(actions => {
      //     const updatedActions = [...actions]
      //     updatedActions[dragIndex] = hoverItem
      //     updatedActions[hoverIndex] = dragItem
      //     return updatedActions
      // });

      // // update actionIds order for form
      // setValue(actions.map(x => x.id.value));
    }; 

    const onActionSelected = (action) => {
      setSelectedAction(action);
    };
    const handleActionChanged = (action) => {
      //update action list
      const allActions = [...actions];
      const actionIndex = allActions.findIndex(x => x.id.value === action.id.value);
      if (actionIndex === -1) {
        allActions.push(action);
      }
      else {
        allActions[actionIndex] = action;
      }
      setActions(allActions);

      onActionChanged(action);
    };
    const handleAddAction = () => {
      const newAction = {
        ...ActionInitialized,
        id: { value: new Date().getTime().toString() },
        projectId: project.id,
      };
      console.log(newAction);
      setSelectedAction(newAction);
    };

    const handleSubmit = (values, { setSubmitting }) => {
        onProjectChanged(values);
        setSubmitting(false);
    };
    const handleComplete = () => {
        console.log('all actions must be complete/deleted first');
    };
    const handleDelete = () => {
        console.log('all actions must be complete/deleted first');
    };

    return (
    <>
      <Formik
        initialValues={project || ProjectInitialized}
        enableReinitialize 
        validationSchema={ProjectValidation}
        onSubmit={handleSubmit}
      >
        <Form>
          <Text
            label="Project title"
            name="title"
            type="text"
            placeholder="title"
          />

          <TextArea
            label="Project notes"
            name="notes"
            placeholder="notes..."
          />

          {actions && (
            <ActionList
              label="Actions"
              name="actions"
              actions={actions}
              // actionIds={project.actions}
              onMoveListItem={onMoveListItem}
              onActionSelected={onActionSelected}
              onActionChanged={handleActionChanged}
            />
          )}
          <button
            type="button"
            className="secondary"
            onClick={handleAddAction}
          >
            Add action
          </button>

          {/* <Text
            label="Email Address"
            name="email"
            type="email"
            placeholder="jane@formik.com"
          />

          <Select label="Job Type" name="jobType">
            <option value="">Select a job type</option>
            <option value="designer">Designer</option>
            <option value="development">Developer</option>
            <option value="product">Product Manager</option>
            <option value="other">Other</option>
          </Select>

          <Checkbox name="acceptedTerms">
            I accept the terms and conditions
          </Checkbox> */}

          <div className='input' style={{display:'block'}}>
            <Button onClick={handleComplete} className='submit' style={{float:'left'}} color="secondary">Complete</Button>
            <Button onClick={handleDelete} className='submit' style={{float:'left'}} color="secondary">Delete</Button>
            <Button type="submit" style={{float:'right'}} color="primary">Save Project</Button>
          </div>

        </Form>
      </Formik>

      {selectedAction && (
        <ActionForm
          openModal={true}
          action={selectedAction}
          onActionChanged={handleActionChanged}
        ></ActionForm>
      )}
    </>
    )
}

ProjectForm.propTypes = {
    project: PropTypes.object.isRequired,
    onProjectChanged: PropTypes.func.isRequired,
    onActionChanged: PropTypes.func.isRequired,
};

export default ProjectForm;