import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../components/Loading";
import Action from "../../components/focus/Action";
import { formatDate, formatDateISO } from  '../../utils/format';
import './styles.css';

export const Focus = () => {
  const [focusPeriod, setFocusPeriod] = useState({});
  const [requestedDate, setRequestedDate] = useState(new Date());
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const getFocus = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`${window.env.apiUrl}/api/focus/${formatDateISO(requestedDate)}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const responseData = await response.json();
  
        setFocusPeriod(responseData);
      } catch (error) {
        //TODO: how to handle errors
        console.error(error);
        // setState({
        //   ...state,
        //   error: error.error,
        // });
      }
    };

    getFocus();
  },[getAccessTokenSilently, requestedDate]);
  
  const saveActionEvents = async () => {
    try {
      const token = await getAccessTokenSilently();
      const request  = JSON.stringify({ models: actionEvents });
      console.log("request",request);
      const response = await fetch(`${window.env.apiUrl}/api/focus/events`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: request
      });

      await response.json();
      // const responseData = await response.json();

      setActionEvents([]);
    } catch (error) {
      //TODO: how to handle errors
      console.error(error);
      // setState({
      //   ...state,
      //   error: error.error,
      // });
    }
  };

  const [actionEvents, setActionEvents] = useState([]);
  const addEvent = (actionId, eventType) => {
    //TODO: add to local storage
    const localDate = new Date();
    console.log(localDate.toUTCString());
    setActionEvents([
        ...actionEvents,
        { id: actionId, eventType, date: new Date()},
    ]);
  };

  const changeRequestedDate = (daysToAdd) => {
    const reqDate = new Date(requestedDate);
    reqDate.setDate(reqDate.getDate() + daysToAdd);
    setRequestedDate(reqDate);
  };

  return (
    <>
      <div className="mb-5">
      {focusPeriod.goals && (
        <div>
          <span className="inline">Goals: </span>
          {focusPeriod.goals.map((goal, i) => (
            <span key={i}>
              {i>0 ? ', ' : ''}
              {goal.isComplete && (<s>{goal.text}</s>)}
              {!goal.isComplete && (goal.text)}
            </span>
          ))}
        </div>
        )}
        
        <div>
          <button className="mr-2"><FontAwesomeIcon icon="filter" /></button>
          {/* <span> */}
            {formatDate(focusPeriod.periodStart)} 
            <b>
            <span onClick={() => changeRequestedDate(-1)}>&lt;&lt;</span>
            {formatDate(requestedDate)} 
            <span onClick={() => changeRequestedDate(1)}>&gt;&gt; </span>
            </b>
            {formatDate(focusPeriod.periodEnd)}
          {/* </span> */}
          {actionEvents.length > 0 && (<button onClick={saveActionEvents} className="ml-2"><FontAwesomeIcon icon="sync" /></button>)}
        </div>

        {actionEvents && (
          <div>
            <h6>Events </h6>
            {actionEvents.map((ae, i) => (
              <p key={i}>id: {ae.id} - type: {ae.eventType} - date: {formatDate(ae.date)}</p>
            ))}
          </div>
        )}

        {focusPeriod.scheduled && (
          <div>
            <h6>Scheduled </h6>
            {focusPeriod.scheduled.map((action, i) => (
              <Action key={i} action={action} onEventAdd={addEvent}></Action>
            ))}
          </div>
        )}

        {focusPeriod.due && (
          <div>
            <h6>Due </h6>
            {focusPeriod.due.map((action, i) => (
              <Action key={i} action={action} onEventAdd={addEvent}></Action>
            ))}
          </div>
        )}

        {focusPeriod.expected && (
          <div>
            <h6>Expected </h6>
            {focusPeriod.expected.map((action, i) => (
              <Action key={i} action={action} onEventAdd={addEvent}></Action>
            ))}
          </div>
        )}

        {focusPeriod.stretch && (
          <div>
            <h6>Stretch </h6>
            {focusPeriod.stretch.map((action, i) => (
              <Action key={i} action={action} onEventAdd={addEvent}></Action>
            ))}
          </div>
        )}

        {focusPeriod.waiting && (
          <div>
            <h6>Waiting </h6>
            {focusPeriod.waiting.map((action, i) => (
              <Action key={i} action={action} onEventAdd={addEvent}></Action>
            ))}
          </div>
        )}

        {focusPeriod.more && (
          <div>
            <h6>More </h6>
            {focusPeriod.more.map((action, i) => (
              <Action key={i} action={action} onEventAdd={addEvent}></Action>
            ))}
          </div>
        )}

      </div>
      <div>{JSON.stringify(focusPeriod)}</div>
    </>
  );
};

export default withAuthenticationRequired(Focus, {
  onRedirecting: () => <Loading />,
});
