import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ActionListItem = (props) => {
    const { action, index, onMoveListItem, onSelected, onActionChanged } = props;
    const [committed, setCommitted] = useState(action.committed);

    const handleSelected = () => {
        onSelected(action);
    };
    const handleCommitChanged = () => {
        const newCommit = action.committed ? undefined : new Date();
        console.log(action.committed, newCommit);
        setCommitted(newCommit);
        const updated = {
            ...action,
            committed: newCommit
        }
        onActionChanged(updated);
    };
    const [{ isDragging }, dragRef] = useDrag({
        type: 'item',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    // eslint-disable-next-line no-unused-vars
    const [spec, dropRef] = useDrop({
        accept: 'item',
        hover: (item, monitor) => {
            const dragIndex = item.index
            const hoverIndex = index
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

            // if dragging down, continue only when hover is smaller than middle Y
            if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
            // if dragging up, continue only when hover is bigger than middle Y
            if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

            onMoveListItem(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

    // Join the 2 refs together into one (both draggable and can be dropped on)
    const ref = useRef(null)
    const dragDropRef = dragRef(dropRef(ref))

    // Make items being dragged transparent, so it's easier to see where we drop them
    const opacity = isDragging ? 0 : 1
    return (
        <div style={{ opacity }} className='input input-bg'>
            <div ref={dragDropRef} className="mr-3" style={{display:'inline'}} >
                <FontAwesomeIcon icon="grip-vertical" className="mr-3" />
                {action.title}
            </div>
            <FontAwesomeIcon onClick={handleSelected} icon="edit" className="mr-3" />
            {committed && (<FontAwesomeIcon onClick={handleCommitChanged} icon="minus-square" className="mr-3" />)}
            {!committed && (<FontAwesomeIcon onClick={handleCommitChanged} icon="plus-square" className="mr-3" />)}
            {/* <button onClick={handleCommitChanged}>{committed ? "Uncommit" : "Commit"}</button> */}
        </div>
    )
}

ActionListItem.propTypes = {
    action: PropTypes.object.isRequired,
    onMoveListItem: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
};

export default ActionListItem;