import React from "react";
import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";

export const StatusComponent = () => {

  return (
    <div>
        <p>UI URL: {window.env.spaUrl}</p>
        <p>API URL: {window.env.apiUrl}</p>
    </div>
  );
};

export default withAuthenticationRequired(StatusComponent, {
  onRedirecting: () => <Loading />,
});
