import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TreeNode from './treenode';
import { useState } from 'react/cjs/react.development';

const Tree = (props) => {
  const { projects, onProjectSelected, onProjectChanged } = props;  
  const [confirmMoveOpen, setConfirmMoveOpen] = useState(false);
  const [moveProjects, setMoveProjects] = useState(null);

  const getChildren = (project) => {
    if (!project.children) return [];
    //preserve ordering from project.children
    return project.children.map(childId => projects.find(p => p.id.value === childId.value));
  }

  const moveProject = (dragId, dropId) => {
    const updated = [...projects];

    //get items
    const dragItem = updated.find(x => x.id.value === dragId);
    const dropItem = updated.find(x => x.id.value === dropId); 
    const dragItemParent = updated.find(x => x.id.value === getParentId(dragItem));
    const dropItemParent = updated.find(x => x.id.value === getParentId(dropItem)); 

    //ask for confirmation
    setMoveProjects({ dragItem, dragItemParent, dropItem, dropItemParent });
    setConfirmMoveOpen(true);
  };

  const getParentId = (project) => {
    if (!project || !project.parents || project.parents.length===0) return null;
    return project.parents[project.parents.length-1].projectId;
  };

  const removeFromCurrentParent = (parent, childId) => {
    if (!parent) return;

    const childIndex = parent.children.indexOf(childId);
    if (childIndex > -1) {
      parent.children.splice(childIndex, 1);
    }
    onProjectChanged(parent);
  }

  const addToNewParent = (parent, beforeChildId, newChildId) => {
    if (!parent) return;

    if (!parent.children) parent.children = [];
    const beforeChildIdx = !beforeChildId ? 0 : parent.children.indexOf(beforeChildId);
    if (beforeChildIdx > -1) {
      parent.children.splice(beforeChildIdx, 0, newChildId);
    }
    onProjectChanged(parent);
  }

  const handleMoveInto = () => {
    const { dragItem, dragItemParent, dropItem } = moveProjects;
    // update dragItem parent reference
    dragItem.parents = !dropItem.parents ? [] : [...dropItem.parents];
    dragItem.parents.push({ projectId: dropItem.id, title: dropItem.title });
    onProjectChanged(dragItem);

    //remove from current parent
    removeFromCurrentParent(dragItemParent, dragItem.id);

    //add to new 
    addToNewParent(dropItem, null, dragItem.id);

    closeConfirm();
  };

  const handleMoveBefore = () => {
    const { dragItem, dragItemParent, dropItem, dropItemParent } = moveProjects;
    // update dragItem parent reference
    dragItem.parents = !dropItem.parents ? null : [...dropItem.parents];
    onProjectChanged(dragItem);

    //remove from current parent
    removeFromCurrentParent(dragItemParent, dragItem.id);

    //add to new 
    addToNewParent(dropItemParent, dropItem.id, dragItem.id);

    closeConfirm();
  };

  const closeConfirm = () => {
    setMoveProjects(null);
    setConfirmMoveOpen(false);
  };


  return (
    <div>
      {projects && projects.filter(x => !x.parents || x.parents.length === 0).map((topLevelProject, i) => (
        <TreeNode 
          key={`${i.toString()}_${topLevelProject.id.value}`}
          project={topLevelProject}
          getChildren={getChildren}
          onProjectSelected={onProjectSelected}
          index={i} moveProject={moveProject}
        ></TreeNode>
      ))}

      {moveProjects && (
        <Modal isOpen={confirmMoveOpen} toggle={closeConfirm}>
          <ModalHeader toggle={closeConfirm}>Are you sure?</ModalHeader>
          <ModalBody>
            <p>Do you want to move <strong>{moveProjects.dragItem.title}</strong></p>
            <Button color="primary" onClick={handleMoveInto}>
              INTO
            </Button>
            {/* {moveProjects.dropItemParent && (<> */}
              <span>   OR   </span>
              <Button color="primary" onClick={handleMoveBefore}>
                BEFORE
              </Button>
              {/* </>)} */}
            <p><strong>{moveProjects.dropItem.title}</strong>?</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={closeConfirm}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>   
      )}
    </div>
  );
};
  
Tree.propTypes = {
  projects: PropTypes.array,
  onProjectSelected: PropTypes.func.isRequired,
};

Tree.defaultProps = {
  projects: [],
};

export default Tree;
