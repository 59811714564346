import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { Text, Select, Checkbox, Datetime } from '../../inputs'
import { ActionEffortOptions, ActionInitialized, ActionValidation } from './action'

export const ActionForm = (props) => {
    const { action, onActionChanged, openModal } = props;  
    const [isOpen, setIsOpen] = useState(openModal);
    
    useEffect(() => {
      if (action) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    },[action]);

    const toggle = () => setIsOpen(!isOpen);

    const handleSubmit = (values, { setSubmitting }) => {
      const updated = {
        ...action,
        ...values,
      };
      onActionChanged(updated);
      setSubmitting(false);
      setIsOpen(false);
    };
    const handleDelete = () => {
      const updated = {
        ...action,
        deleted: new Date(),
      };
      onActionChanged(updated);
      setIsOpen(false);
    };
    
    const getRepeatDescription = (values) => {
      const monthDesc =
      values.schedule.repeatMonth > 1 ? " months" : " month";
      const dayDesc = values.schedule.repeatDay > 1 ? " days" : " day";
      if (
        values.schedule.repeatDay > 0 &&
        values.schedule.repeatMonth > 0
      ) {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        return `Repeats every ${months[values.schedule.repeatMonth - 1]} ${values.schedule.repeatDay}`;
      }
      if (action.schedule.repeatDay > 0) {
        return `Repeats every ${values.schedule.repeatDay}${dayDesc}`;
      }
      if (action.schedule.repeatMonth > 0) {
        return `Repeats every ${values.schedule.repeatMonth}${monthDesc}`;
      }
      return "";
    };

    return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Formik
        initialValues={action || ActionInitialized}
        enableReinitialize 
        validationSchema={ActionValidation}
        onSubmit={handleSubmit}
      >
      {({ values }) => (
        <Form>
          <ModalHeader toggle={toggle}>
          </ModalHeader>

          <ModalBody>
            <Text
              label="Action title"
              name="title"
              type="text"
              placeholder="title"
            />

            <Select label="Effort" name="effort">
              <option value="">- Select -</option>
              {ActionEffortOptions.map((opt, index) => (
                <option key={index} value={opt.effort}>{opt.text}</option>
              ))}
            </Select>

            <Checkbox name="important">Important</Checkbox> 
            <Checkbox name="urgent">Urgent</Checkbox> 

            <Datetime label="Due" name="due" />

            <label>Checklist</label>
            <FieldArray name="checklist">
            {({ insert, remove, push }) => (
              <div>
                {values.checklist && values.checklist.length > 0 &&
                  values.checklist.map((item, index) => (
                    <div className="row" key={index}>
                      <div className="col">
                        <Field
                          name={`checklist.${index}.key`}
                          placeholder="checklist item"
                          type="text"
                        />
                        <ErrorMessage
                          name={`checklist.${index}.key`}
                          component="div"
                          className="field-error"
                        />
                        <button
                          type="button"
                          className="secondary"
                          onClick={() => remove(index)}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                <button
                  type="button"
                  className="secondary"
                  onClick={() => push({ key: '', value: false })}
                >
                  Add checklist item
                </button>
              </div>
            )}
            </FieldArray>

            <label>Schedule</label>
            <button
              type="button"
              className="secondary"
              onClick={() => { values.schedule ? values.schedule = {} : values.schedule = undefined; }}
            >
              {!values.schedule && (<span>Add</span>)}
              {values.schedule && (<span>Remove</span>)}
            </button>
            {values.schedule && (
              <div>
                <Checkbox name="schedule.repeatOnComplete">Repeat On Complete</Checkbox> 
                <Datetime label="Start" name="schedule.start" />
                <Text label="RepeatDay" name="schedule.repeatDay" type="number" min="1" />
                <Text label="RepeatMonth" name="schedule.repeatMonth" type="number" min="1" />
                <p>{getRepeatDescription(values)}</p>
              </div>
            )}

            <label>Tags</label>
            <FieldArray name="tags">
            {({ insert, remove, push }) => (
              <div>
                {values.tags && values.tags.length > 0 &&
                  values.tags.map((item, index) => (
                    <div className="row" key={index}>
                      <div className="col">
                        <Field
                          name={`tags.${index}`}
                          placeholder="tag name"
                          type="text"
                        />
                        <ErrorMessage
                          name={`tags.${index}`}
                          component="div"
                          className="field-error"
                        />
                        <button
                          type="button"
                          className="secondary"
                          onClick={() => remove(index)}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                <button
                  type="button"
                  className="secondary"
                  onClick={() => push('')}
                >
                  Add tag
                </button>
              </div>
            )}
            </FieldArray>

          </ModalBody>

          <ModalFooter>
            <Button onClick={toggle} className='submit' color="secondary">
              Cancel
            </Button>
            <Button onClick={handleDelete} className='submit' style={{float:'left'}} color="secondary">Delete</Button>
            <Button type="submit" style={{float:'right'}} color="primary">Save Action</Button>
          </ModalFooter>

        </Form>
      )}
      </Formik>
    </Modal>
    )
}

ActionForm.propTypes = {
  action: PropTypes.object.isRequired,
  onActionChanged: PropTypes.func.isRequired,
};

export default ActionForm;