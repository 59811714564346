export const formatDateTime = (value) => {
    if (value) {
      return new Date(String(value)).toLocaleDateString(undefined, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    }
    return "";
};

export const formatDate = (value) => {
  if (value) {
    return new Date(String(value)).toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });
  }
  return "";
};

export const formatDateISO = (value) => {
  if (value) {
    return new Date(String(value)).toISOString();
  }
  return "";
};