import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLink,
  faPowerOff,
  faUser,
  faEllipsisH,
  faFilter,
  faSync,
  faPlay,
  faStop,
  faArrowUp,
  faArrowRight,
  faCheckSquare,
  faFile, faFolder, faFolderOpen, faChevronDown, faChevronRight,
  faGripVertical, faEdit, faPlusSquare, faMinusSquare
} from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faEllipsisH);

  library.add(faFilter);
  library.add(faSync);
  library.add(faPlay);
  library.add(faStop);
  library.add(faArrowUp);
  library.add(faArrowRight);
  library.add(faCheckSquare);

  library.add(faFile);
  library.add(faFolder);
  library.add(faFolderOpen);
  library.add(faChevronDown);
  library.add(faChevronRight);

  library.add(faGripVertical);
  library.add(faEdit);
  library.add(faPlusSquare);
  library.add(faMinusSquare);

}

export default initFontAwesome;
