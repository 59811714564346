import React from "react";
import PropTypes from 'prop-types';
import { Button } from "reactstrap";
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { Date } from '../../inputs'
import { PeriodInitialized, PeriodValidation } from './period'
import { formatDate } from  '../../../utils/format';

export const PeriodForm = (props) => {
    const { period, onPeriodChanged } = props;  
    // console.log(period);

    const handleSubmit = (values, { setSubmitting }) => {
      const updated = {
        ...period,
        ...values,
      };
      onPeriodChanged(updated);
    };
    

    return (
      <Formik
        initialValues={period || PeriodInitialized}
        enableReinitialize 
        validationSchema={PeriodValidation}
        onSubmit={handleSubmit}
      >
      {({ values }) => (
        <Form>
            <div>
            <p>Current Period: {formatDate(period.start)} - {formatDate(period.end)}</p>
            <Date label="Start" name="start" />
            <Date label="End" name="end" />

            <p>ExpectedCommittedEffort: {period.expectedCommittedEffort}</p>
            <p>CommittedEffort: {period.committedEffort}</p>
            <p>ExpectedEffort: {JSON.stringify(period.ExpectedEffort)}</p>
            <p>StretchEffort: {JSON.stringify(period.StretchEffort)}</p>
            <p>ActualEffort: {JSON.stringify(period.ActualEffort)}</p>

            <label>Goals</label>
            <FieldArray name="goals">
            {({ insert, remove, push }) => (
                <div>
                {values.goals && values.goals.length > 0 &&
                    values.goals.map((item, index) => (
                    <div className="row" key={index}>
                        <div className="col">
                        <Field
                            name={`goals.${index}.text`}
                            placeholder="goal"
                            type="text"
                        />
                        <ErrorMessage
                            name={`goals.${index}.text`}
                            component="div"
                            className="field-error"
                        />
                        <button
                            type="button"
                            className="secondary"
                            onClick={() => remove(index)}
                        >
                            X
                        </button>
                        </div>
                    </div>
                    ))}
                <button
                    type="button"
                    className="secondary"
                    onClick={() => push({text: '', isComplete: false})}
                >
                    Add goal
                </button>
                </div>
            )}
            </FieldArray>

            {/* <label>Committed Actions</label>
            <FieldArray name="committedActions">
            {({ insert, remove, push }) => (
                <div>
                {values.committedActions && values.committedActions.length > 0 &&
                    values.committedActions.map((item, index) => (
                    <div className="row" key={index}>
                        <div className="col">
                        <span key={index}><br />{JSON.stringify(item)}</span>
                         <button
                            type="button"
                            className="secondary"
                            onClick={() => remove(index)}
                        >
                            X
                        </button> 
                        </div>
                    </div>
                ))}
                </div>
            )}
            </FieldArray> */}

            {period.committedActions && (
              <div>
                <label>Committed Actions</label> 
                {period.committedActions.map((x, i) => (
                    <span key={i} className={`${x.isComplete ? "strike" : ""}`}>
                        {x.title}<br />
                    </span>
                ))}
              </div>
            )}
          </div>

        <Button type="submit" style={{float:'right'}} color="primary">Save Period</Button>

        </Form>
      )}
      </Formik>
    )
}

PeriodForm.propTypes = {
  period: PropTypes.object.isRequired,
  onPeriodChanged: PropTypes.func.isRequired,
};

export default PeriodForm;