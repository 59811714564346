import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { ActionListItem } from './actionlistitem'

export const ActionList = ({ label, ...props }) => {
  const [meta, helpers] = useField(props);
  const { setValue } = helpers;
  const { actions, onActionSelected, onActionChanged } = props;      
  const [actionsList, setActionsList] = useState(actions);

  useEffect(() => {
    setActionsList(actions);
  },[actions]);

  const moveListItem = (dragIndex, hoverIndex) => {
    const dragItem = actionsList[dragIndex];
    const hoverItem = actionsList[hoverIndex];
    // Swap places of dragItem and hoverItem in the list
    setActionsList(actionsList => {
        const updatedActions = [...actionsList]
        updatedActions[dragIndex] = hoverItem
        updatedActions[hoverIndex] = dragItem
        return updatedActions
    });

    // update actionIds order for form
    setValue(actionsList.map(x => x.id.value));
  }; 

  return (
        <div>
            <label>{label}</label>
            {actionsList.map((action, index) => (
                <ActionListItem
                    key={action.id.value}
                    index={index}
                    action={action}
                    onMoveListItem={moveListItem}
                    onSelected={onActionSelected}
                    onActionChanged={onActionChanged}
                />
            ))}
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    )
}

ActionList.propTypes = {
  actions: PropTypes.array.isRequired,
  onActionSelected: PropTypes.func.isRequired,
  onMoveListItem: PropTypes.func.isRequired,
};

export default ActionList;