import * as Yup from 'yup';

export const PeriodInitialized = {
    id: null,
    start: null,
    end: null,

    expectedCommittedEffort: 0,
    expectedEffort: null,
    stretchEffort: null,

    goals: null,
    initialCommittedActions: null,
    committedActions: null,
    actualEffort: null,
};

export const PeriodValidation = Yup.object().shape({
    // id: Yup.string()
    // .required('Required'),
    start: Yup.date()
    .required('Required'),
    end: Yup.date()
    .required('Required'),
});
